import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import {
  createNoAvailableSlotsViewModel,
  memoizedNoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {
  createTimeSlotsSelectionViewModel,
  memoizedTimeSlotsSelectionViewModel,
  TimeSlotsSelectionViewModel,
} from '../timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import {
  createTimeSlotsNotificationViewModel,
  memoizedTimeSlotsNotificationViewModel,
} from '../timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';
import {
  createWeekPickerViewModel,
  memoizedWeekPickerViewModel,
} from '../weekPickerViewModel/weekPickerViewModel';
import {
  getTimeSlotsAvailabilityStatuses,
  filterTimeSlotsAvailabilityStatusesByDate,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';
import {
  formatLocalDateTimeToDay,
  formatLocalDateTimeToShortWeekday,
  formatLocalDateTimeToWeekday,
  formatMonth,
  getDateTimeFromLocalDateTime,
  getStartOfNextDateLocalDateTime,
} from '../../../../utils/dateAndTime/dateAndTime';
import { WeeklyTimeSlotsLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import {
  getSlotsPerDaysInSelectedRange,
  getToday,
  SlotsFactory,
  SlotsPerDay,
} from '../../../../utils/slotsPerDay/slotsPerDay';

export const memoizedWeeklyTimeSlotsLayoutViewModel: MemoizedViewModalFactory<WeeklyTimeSlotsLayoutViewModel> =
  {
    dependencies: {
      settings: ['dateAndTimeSectionHeader'],
      state: [
        'slotsStatus',
        'availableSlots',
        'selectedRange',
        'selectedTimezone',
        'selectedTime',
      ],
      subDependencies: [
        memoizedTimezoneSelectionViewModel.dependencies,
        memoizedTimeSlotsNotificationViewModel.dependencies,
        memoizedWeekPickerViewModel.dependencies,
        memoizedNoAvailableSlotsViewModel.dependencies,
        memoizedTimeSlotsSelectionViewModel.dependencies,
      ],
    },
    createViewModel: createWeeklyTimeSlotsLayoutViewModel,
  };

export function createWeeklyTimeSlotsLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): WeeklyTimeSlotsLayoutViewModel {
  const { getContent, settingsParams, experiments } = context;
  const { slotsStatus, availableSlots, selectedRange } = state;

  let slotsPerDays;
  let timeSlotsNotificationViewModel;
  let weekPickerViewModel;
  let noAvailableSlotsViewModel;
  if (selectedRange) {
    const timeSlotsAvailabilityStatuses: Map<
      string,
      TimeSlotAvailabilityStatus
    > = getTimeSlotsAvailabilityStatuses(availableSlots);

    const isRefactorSlotsPerDayEnabled = experiments.enabled(
      'specs.bookings.refactorSlotsPerDay',
    );
    if (isRefactorSlotsPerDayEnabled) {
      const slotsFactory = createTimeSlotsFactory(
        timeSlotsAvailabilityStatuses,
      );
      slotsPerDays =
        getSlotsPerDaysInSelectedRange<TimeSlotsSelectionViewModel>({
          slotsFactory,
          state,
          context,
        });
    } else {
      slotsPerDays = deprecatedGetSlotsPerDays({
        timeSlotsAvailabilityStatuses,
        state,
        context,
      });
    }

    timeSlotsNotificationViewModel = createTimeSlotsNotificationViewModel({
      timeSlotsAvailabilityStatuses,
      state,
      context,
    });

    weekPickerViewModel = createWeekPickerViewModel({ state, context });

    noAvailableSlotsViewModel = createNoAvailableSlotsViewModel({
      state,
      context,
    });
  }

  return {
    bodyTitle: getContent({
      settingsParam: settingsParams.dateAndTimeSectionHeader,
      translationKey: 'app.settings.defaults.widget.date-and-time-header',
    }),
    slotsStatus,
    timezoneSelectionViewModel: createTimezoneSelectionViewModel({
      state,
      context,
    }),
    weekPickerViewModel,
    timeSlotsNotificationViewModel,
    slotsPerDays,
    noAvailableSlotsViewModel,
  };
}

const deprecatedGetSlotsPerDays = ({
  timeSlotsAvailabilityStatuses,
  state,
  context,
}: {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
  state: CalendarState;
  context: CalendarContext;
}): SlotsPerDay<TimeSlotsSelectionViewModel>[] => {
  const { selectedRange, selectedTimezone, selectedTime } = state;
  const { businessInfo, t } = context;
  const { from, to } = selectedRange!;
  const locale = businessInfo!.dateRegionalSettingsLocale!;
  const today = getToday(selectedTimezone!);

  const slotsDetailsPerDay = [];
  let dateAsLocalDate = from;
  let date = getDateTimeFromLocalDateTime(from);
  do {
    const filteredTimeSlotsAvailabilityStatuses =
      filterTimeSlotsAvailabilityStatusesByDate(
        timeSlotsAvailabilityStatuses,
        dateAsLocalDate,
      );

    const firstTimeSlot = timeSlotsAvailabilityStatuses.keys().next().value;
    const shouldHighlightedSlotDetailsOnRender =
      filteredTimeSlotsAvailabilityStatuses.has(firstTimeSlot) && !selectedTime;
    const timeSlotsSelectionViewModel = createTimeSlotsSelectionViewModel({
      timeSlotsAvailabilityStatuses: filteredTimeSlotsAvailabilityStatuses,
      shouldHighlightedSlotDetailsOnRender,
      state,
      context,
    });

    const day = formatLocalDateTimeToDay(dateAsLocalDate, locale);
    const month = formatMonth(dateAsLocalDate, locale);
    const dayWithoutSlotsSrOnlyText = t(
      'app.week-availability.accessibility.day-without-slots',
      { month, day },
    );
    const dayWithSlotsSrOnlyText = t(
      'app.week-availability.accessibility.day-with-slots',
      { month, day },
    );
    const dayDetailsAriaLabel = t('app.week-availability.accessibility.day', {
      weekday: formatLocalDateTimeToWeekday(dateAsLocalDate, locale),
      day,
    });

    slotsDetailsPerDay.push({
      date: dateAsLocalDate,
      slots: timeSlotsSelectionViewModel,
      weekday: formatLocalDateTimeToShortWeekday(dateAsLocalDate, locale),
      day,
      isToday: date.getTime() === today.getTime(),
      isPastDate: date < today,
      accessibility: {
        dayWithoutSlotsSrOnlyText,
        dayWithSlotsSrOnlyText,
        dayDetailsAriaLabel,
      },
    });

    dateAsLocalDate = getStartOfNextDateLocalDateTime(dateAsLocalDate);
    date = getDateTimeFromLocalDateTime(dateAsLocalDate);
  } while (date <= getDateTimeFromLocalDateTime(to));

  return slotsDetailsPerDay;
};

export const createTimeSlotsFactory =
  (
    timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>,
  ): SlotsFactory<TimeSlotsSelectionViewModel> =>
  (
    dateAsLocalDate: string,
    state: CalendarState,
    context: CalendarContext,
  ): TimeSlotsSelectionViewModel => {
    const { selectedTime } = state;
    const filteredTimeSlotsAvailabilityStatuses =
      filterTimeSlotsAvailabilityStatusesByDate(
        timeSlotsAvailabilityStatuses,
        dateAsLocalDate,
      );

    const firstTimeSlot = timeSlotsAvailabilityStatuses.keys().next().value;
    const shouldHighlightedSlotDetailsOnRender =
      filteredTimeSlotsAvailabilityStatuses.has(firstTimeSlot) && !selectedTime;
    return createTimeSlotsSelectionViewModel({
      timeSlotsAvailabilityStatuses: filteredTimeSlotsAvailabilityStatuses,
      shouldHighlightedSlotDetailsOnRender,
      state,
      context,
    });
  };
