import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createDailyTimeSlotsMonthlyPickerLayoutViewModel,
  memoizedDailyTimeSlotsMonthlyPickerLayoutViewModel,
} from '../dailyTimeSlotsMonthlyPickerLayoutViewModel/dailyTimeSlotsMonthlyPickerLayoutViewModel';
import {
  createWeeklyTimeSlotsLayoutViewModel,
  memoizedWeeklyTimeSlotsLayoutViewModel,
} from '../weeklyTimeSlotsLayoutViewModel/weeklyTimeSlotsLayoutViewModel';
import {
  createWeeklyTimetableLayoutViewModel,
  memoizedWeeklyTimetableLayoutViewModel,
} from '../weeklyTimetableLayoutViewModel/weeklyTimetableLayoutViewModel';
import {
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { BodyViewModel } from './bodyViewModel.types';

export const memoizedBodyViewModelViewModel: MemoizedViewModalFactory<BodyViewModel> =
  {
    dependencies: {
      settings: ['calendarLayout'],
      subDependencies: [
        memoizedDailyTimeSlotsMonthlyPickerLayoutViewModel.dependencies,
        memoizedWeeklyTimeSlotsLayoutViewModel.dependencies,
        memoizedWeeklyTimetableLayoutViewModel.dependencies,
      ],
    },
    createViewModel: createBodyViewModel,
  };

export function createBodyViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): BodyViewModel {
  const { settings, settingsParams } = context;

  if (isWeeklyTimeSlotsLayout(settings, settingsParams)) {
    return {
      weeklyTimeSlotsLayout: createWeeklyTimeSlotsLayoutViewModel({
        state,
        context,
      }),
    };
  }

  if (isWeeklyTimetableLayout(settings, settingsParams)) {
    return {
      weeklyTimetableLayout: createWeeklyTimetableLayoutViewModel({
        state,
        context,
      }),
    };
  }

  return {
    dailyTimeSlotsMonthlyPickerLayout:
      createDailyTimeSlotsMonthlyPickerLayoutViewModel({
        state,
        context,
      }),
  };
}
