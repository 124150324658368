import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createWeekPickerViewModel,
  memoizedWeekPickerViewModel,
} from '../weekPickerViewModel/weekPickerViewModel';
import { WeeklyTimetableLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import {
  getSlotsPerDaysInSelectedRange,
  SlotsFactory,
} from '../../../../utils/slotsPerDay/slotsPerDay';
import {
  AgendaSlotsViewModel,
  createAgendaSlotsViewModel,
  memoizedAgendaSlotsViewModel,
} from '../agendaSlotsViewModel/agendaSlotsViewModel';

export const memoizedWeeklyTimetableLayoutViewModel: MemoizedViewModalFactory<WeeklyTimetableLayoutViewModel> =
  {
    dependencies: {
      state: ['slotsStatus', 'availableSlots', 'selectedRange'],
      subDependencies: [
        memoizedWeekPickerViewModel.dependencies,
        memoizedAgendaSlotsViewModel.dependencies,
      ],
    },
    createViewModel: createWeeklyTimetableLayoutViewModel,
  };

export function createWeeklyTimetableLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): WeeklyTimetableLayoutViewModel {
  const { slotsStatus, selectedRange } = state;

  let slotsPerDays;
  let weekPickerViewModel;
  if (selectedRange) {
    const slotsFactory = createAgendaSlotsFactory();
    slotsPerDays = getSlotsPerDaysInSelectedRange<AgendaSlotsViewModel>({
      slotsFactory,
      state,
      context,
    });
    weekPickerViewModel = createWeekPickerViewModel({ state, context });
  }

  return {
    slotsStatus,
    weekPickerViewModel,
    slotsPerDays,
  };
}

export const createAgendaSlotsFactory =
  (): SlotsFactory<AgendaSlotsViewModel> =>
  (dateAsLocalDate: string, state: CalendarState, context: CalendarContext) => {
    const { availableSlots } = state;
    const slotsAtSpecificDate = availableSlots?.availabilityEntries || []; // TODO filter slots by date;

    return createAgendaSlotsViewModel({
      state,
      context,
      slots: slotsAtSpecificDate,
    });
  };
