import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { isWidget } from '../../../../utils/presets';

export type BackButtonViewModel = {
  label: string;
};

export const memoizedBackButtonViewModel: MemoizedViewModalFactory<
  Optional<BackButtonViewModel>
> = {
  dependencies: {
    state: ['rescheduleBookingDetails'],
  },
  createViewModel: createBackButtonViewModel,
};

export function createBackButtonViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<BackButtonViewModel> {
  const { t, experiments, preset } = context;
  const { rescheduleBookingDetails } = state;

  const isWidgetPreset = isWidget(preset);
  const isBackButtonEnabled = experiments.enabled(
    'specs.bookings.calendarBackButton',
  );

  if (!isBackButtonEnabled || rescheduleBookingDetails || isWidgetPreset) {
    return;
  }

  return {
    label: t('app.calendar.back'),
  };
}
